<template>
  <div class="student-products" >
    <h4 class="mb-4">Профиль ученика</h4>
    <template v-if="account.isLoaded">
        <div class="widget row">
            <div class="col-xl-2">
                <b-form-group
                    label='Аватар'
                    class='col-12'
                    label-for='avatar'
                >
                    <div class="d-flex flex-column justify-center">
                        <img :src="account.avatar_url" alt="#"/>
                    </div>
                </b-form-group>
            </div>
            <div class="col-xl-10">
                <students-profile-form></students-profile-form>
                <student-potential-lessons :student-id="studentId"></student-potential-lessons>
                <teacher-increased-salary-counter v-if="isTeacher && getUserId" class="mt-4" :student-id="studentId" :teacher-id="getUserId"></teacher-increased-salary-counter>
                <div class="row mt-3">
                    <div class="col-xl-12">
                        <schedule-list-component :student-id="studentId" @update="updateDataOnTables" ref="scheduleTable"></schedule-list-component>
                        <lessons-list-component :student-id="studentId" @update="updateDataOnTables" ref="lessonsTable"></lessons-list-component>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
      <b-alert show variant="primary">Не удалось загрузить данные</b-alert>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import moment from "moment";
import EditLessonModal from "@/components/Modal/EditLessonModal.vue";
import ScheduleListComponent from "@/components/admin/lessons/ScheduleListComponent.vue";
import LessonsListComponent from "@/components/admin/lessons/LessonsListComponent.vue";
import StudentsProfileForm from "@/components/admin/students/StudentsProfileForm.vue";
import StudentPotentialLessons from "@/components/admin/students/StudentPotentialLessons.vue";
import TeacherIncreasedSalaryCounter from "@/components/admin/students/TeacherIncreasedSalaryCounter.vue";

export default {
    name: "StudentProfileShow",
    components: {StudentPotentialLessons, StudentsProfileForm, LessonsListComponent, ScheduleListComponent, TeacherIncreasedSalaryCounter},
    props: {
        studentId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
          account: {
              isLoaded: false,
              avatar_url: 'https://place-hold.it/200x200',
          },
          studentLvls: null,
        };
    },
    computed: {
      moment() {
        return moment
      },
        ...mapGetters({
            getStudentDetail: "students/getStudentDetail",
            getCountriesCode: "common/getCountriesCodes",
            getRole: "user/getRole",
            getUserId: "user/getUserId"
        }),
        isTeacher() {
          return this.getRole === 'teacher'
        },
        baseUrl() {
          return process.env.VUE_APP_API_BASE_URL;
        },
    },
    async created() {
        await this.fetchAccountData()
        console.log(this.$refs.lessons)
        this.account.isLoaded = !!this.getStudentDetail.id
    },
    methods: {
        dayjs,
        async fetchAccountData() {
            try {
                const {data} = await this.$axios.get(`/crm/students/website-account/${this.studentId}`);
                if (data.data.avatar_url) this.account.avatar_url = data.data.avatar_url;
            } catch (ex) {
                console.log("can't fetch student's account: " + ex);
            }
        },
        updateDataOnTables() {
            this.$refs.scheduleTable.fetchScheduleData(true)
            this.$refs.lessonsTable.fetchLessonsData()
        }
    }
  };
</script>

<style lang="scss" scoped>

</style>