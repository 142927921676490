<template>
  <b-card no-body class="mb-3 schedule-accordion">
    <b-card-header header-tag="header" class="p-0 schedule-accordion-header" role="tab">
      <b-button block v-b-toggle.accordion-1 variant="info" class="schedule-accordion-button text-left">Расписание регулярных занятий</b-button>
      <b-button variant="info" class="new-schedule-btn" @click="openCreateScheduleModal">Создать расписание</b-button>
    </b-card-header>
    <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
      <b-card-body class="d-flex flex-column schedule-card-body">
        <template v-if="schedule.data && Object.keys(schedule.data).length">
          <table class='table table-hover'>
            <thead class='table_head'>
              <tr class="text-left">
                  <th>Дни недели</th>
                  <th v-if="isManager">Учитель</th>
                  <th>Период</th>
                  <th>Время</th>
                  <th>Длительность</th>
                  <th>Язык</th>
                  <th>Управление</th>
              </tr>
            </thead>
            <transition-group name="schedule-list" tag="tbody">
              <tr v-for="(item, key) in schedule.data"
                  :key="key"
                  class="text-left font-weight-bold schedule-item"
                  :class="{
                    'schedule-non-actual': !item.is_actual,
                    'tint-schedule': getScheduleHighlight === item.id
                  }"
                  @mouseover="setScheduleHighlight(item.id)"
                  @mouseleave="setScheduleHighlight(null)"
              >
                <td class="schedule-weekdays">{{ item.weekdays }}</td>
                  <td v-if="isManager" >{{item.teacher_name}}</td>
                <td>{{schedulePeriod(item.begin_date, item.end_date)}}</td>
                <td>{{`С ` + dayjs(item.begin_date + ' ' + item.begin_time).format('HH:mm') + ` по ` + dayjs(item.begin_date + ' ' + item.end_time).format('HH:mm')}}</td>
                <td>{{item.duration}} минут</td>
                <td>{{ scheduleLanguage(item.language) }}</td>
                <td>
                  <template v-if="item.is_actual">
                    <button class="btn btn-primary mr-2" @click="openLessonDetails(item.id, !isManager, true)">
                      <span class='glyphicon glyphicon-edit' />
                    </button>
                    <button class="btn btn-primary" @click="openCancelScheduleModal(item.id)">
                      <span class='glyphicon glyphicon-off' />
                    </button>
                  </template>
                </td>
              </tr>
            </transition-group>
          </table>
            <div v-if='schedule.meta && schedule.meta.last_page !== 1' class='py-1 overflow-auto'>
              <transition name="button-more-fade">
                <b-button variant="primary w-100" @click="lessonDataNextPage" v-if="schedule.meta.current_page !== schedule.meta.last_page">
                  <b-spinner v-if="isLoading" class="mr-2" style="width: 1.3rem; height: 1.3rem;" label="Loading"></b-spinner>
                  <span :class="{ captionPadding: isLoading }">Показать больше расписаний</span>
                </b-button>
              </transition>
            </div>
        </template>
        <template v-else>
          <b-alert show variant="primary">Результатов не найдено</b-alert>
        </template>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import dayjs from "dayjs";
import EditLessonModal from "@/components/Modal/EditLessonModal.vue";
import CancelScheduleModal from "@/components/Modal/CancelScheduleModal.vue";
import {mapActions, mapGetters} from "vuex";
import CreateLessonModal from "@/components/Modal/CreateLessonModal.vue";
import moment from "moment/moment";

export default {
  name: "ScheduleListComponent",
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
      return {
          isLoading: false,
          isManager: false,
          schedule: {
              data: [],
              meta: null,
              page: 1
          },
      };
  },
  computed: {
      ...mapGetters({
          getScheduleHighlight: "scheduler/getScheduleHighlight",
          getRole: "user/getRole"
      })
  },
  async created() {
      this.isManager = this.getRole === 'admin'
      console.log(this.isManager)
      this.schedule.page = this.$route.query['schedule-page'] ? this.$route.query['schedule-page'] : this.schedule.page
      await this.fetchScheduleData()
  },
  methods: {
    ...mapActions({
      setScheduleHighlight: "scheduler/setScheduleHighlight"
    }),
    dayjs,
    async fetchScheduleData(isPageReset = false) {
      this.isLoading = true
        this.schedule.page = isPageReset ? 1 : this.schedule.page
      try {
        const { data } = await this.$axios.get(`crm/students/${this.studentId}/schedule?page=${this.schedule.page}`);
        this.schedule.page !== 1 ? this.schedule.data = this.schedule.data.concat(data.data) : this.schedule.data = data.data
        this.schedule.meta = data.meta;
        this.isLoading = false
      } catch (ex) {
        this.isLoading = false
        this.showErrorNotify('Не удалось загрузить расписание')
        console.log("can't fetch student's schedule: " + ex);
      }
    },
    scheduleLanguage(language) {
      let scheduleLanguage = language
      let scheduleLanguageTitle = 'Русский'
      scheduleLanguageTitle = scheduleLanguage === 'ru' ? 'Русский' : 'Английский'
      return scheduleLanguageTitle
    },
    schedulePeriod(begin_date, end_date = null) {
      let period = `С ` + dayjs(begin_date).format('DD.MM.YYYY')
      if (end_date) {
        period += ` по ` + dayjs(end_date).format('DD.MM.YYYY')
      }
      return period
    },
    openLessonDetails(teacherId, isTeacher = false, isSchedule = false) {
      this.$modal.show(
          EditLessonModal,
          {
            teacher: isTeacher,
            lessonId: teacherId,
            isSchedule: isSchedule,
            actionHandler: this.updateDataAfterAction,
            removeHandler: this.updateDataAfterAction,
          },
          {
            height: 'auto',
            maxHeight: 900,
            scrollable: true,
          }
      );
    },
    openCancelScheduleModal(scheduleId) {
      this.$modal.show(
          CancelScheduleModal,
          {
              actionHandler: this.updateDataAfterAction,
            scheduleId: scheduleId,
          },
          {
            height: 'auto',
            maxHeight: 900,
            scrollable: true,
          }
      );
    },
    openCreateScheduleModal() {
      this.$modal.show(
          CreateLessonModal,
          {
              teacher: !this.isManager,
              isSchedule: true,
              lessonDatePrepared: moment().format('DD-MM-YYYY'),
              studentIdPrepared: Number(this.studentId),
              beforeCreatedAction:  this.updateDataAfterAction
          },
          {
              height: 'auto',
              maxHeight: 900,
              scrollable: true,
          },
      );
    },
    lessonDataNextPage() {
      this.schedule.page++
      this.fetchScheduleData()
    },
    showErrorNotify(text) {
      this.$toasted.error(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    updateDataAfterAction() {
        this.$emit('update')
    }
  },
}
</script>

<style scoped lang="scss">
.schedule-accordion-header {
    position: relative!important;
  border: none!important;
}
.schedule-accordion-button{
    padding: 10px 15px;
  background-color: var(--sidebar-bg-color);
  border-color: var(--sidebar-bg-color);

}

.new-schedule-btn {
    position: absolute;
    zoom: 0.9;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.schedule-item {
  transition: 0.15s;
}

.schedule-weekdays {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.schedule-card-body {
    overflow: auto;
}

.tint-schedule {
  background: rgba(0, 43, 73, 0.84)!important;
  color: #FFFFFF!important;
}

.schedule-non-actual {
  background: #EAF0F6;
  color: rgba(87, 99, 121, 0.94);
}

.schedule-accordion-card-header {
  font-weight: 600;
}

.captionPadding {
  margin-left: 10px;
}

.button-more-fade-enter-active, .button-more-fade-leave-active {
  transition: opacity .5s;
}

.button-more-fade-enter, .button-more-fade-leave-to {
  opacity: 0;
}

.schedule-list-item {
  display: inline-block;
  margin-right: 10px;
}
.schedule-list-enter-active, .schedule-list-leave-active {
  transition: all 0.5s;
}
.schedule-list-enter, .schedule-list-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

</style>