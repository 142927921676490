<template>
    <div class="row">
        <b-form-group
            label='ФИО студента'
            class='col-4'
            label-for="fio"
        >
            <b-form-input
                id="fio"
                type="text"
                placeholder="Имя ученика"
                autocomplete="off"
                readonly
                v-model="account.full_name"
            />
        </b-form-group>

        <b-form-group
            label='Пол'
            class='col-4'
            label-for="sex"
        >
            <b-form-input
                id="sex"
                type="text"
                placeholder="Пол"
                autocomplete="off"
                readonly
                v-model="studentGender"
            />
        </b-form-group>

        <b-form-group
            label='Дата рождения'
            class='col-4'
            label-for="birthdate"
        >
            <b-form-input
                id="birthdate"
                type="text"
                placeholder="Дата рождения"
                autocomplete="off"
                readonly
                v-model="studentBirthdate"
            />
        </b-form-group>

        <b-form-group
            label='Возраст'
            class='col-4'
            label-for="age"
        >
            <b-form-input
                id="age"
                type="text"
                placeholder="Возраст"
                autocomplete="off"
                readonly
                v-model="account.age"
            />
        </b-form-group>

        <b-form-group
            label='Возрастная группа'
            class='col-4'
            label-for="age"
        >
            <b-form-input
                id="age"
                type="text"
                placeholder="Возрастная группа"
                autocomplete="off"
                readonly
                v-model="account.age_group"
            />
        </b-form-group>

        <b-form-group
            label='Имя родителя'
            class='col-4'
            label-for="parent_name"
        >
            <b-form-input
                id="parent_name"
                type="text"
                placeholder="Имя родителя"
                autocomplete="off"
                readonly
                v-model="account.parent_name"
            />
        </b-form-group>

        <b-form-group
            label='Страна'
            class='col-4'
            label-for="country"
        >
            <b-form-input
                id="country"
                type="text"
                placeholder="Страна"
                autocomplete="off"
                readonly
                v-model="studentCountry"
            />
        </b-form-group>

        <b-form-group
            label='Часовой пояс'
            class='col-4'
            label-for="timezone"
        >
            <b-form-input
                id="timezone"
                type="text"
                placeholder="Часовой пояс"
                autocomplete="off"
                readonly
                v-model="account.timezone"
            />
        </b-form-group>

        <b-form-group
            label='Игровой опыт'
            class='col-4'
            label-for="experience"
        >
            <b-form-input
                id="experience"
                type="text"
                placeholder="Игровой опыт"
                autocomplete="off"
                readonly
                v-model="account.experience_title"
            />
        </b-form-group>

        <b-form-group
            label='Уровень'
            class='col-4'
            label-for="lvl"
        >
            <div class="profile-data-lvl-wrap">
                <span>{{account.lvl}} - {{getLvlTitle}}:&nbsp;&nbsp;</span>
                <svg
                    v-for="star in 5"
                    :key="star"
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="lvl-star"
                    :class="{ active: star <= getSubLvl }"
                >
                    <path
                        d="M7.11684 0.662837C7.49282 -0.0450765 8.50718 -0.0450786 8.88316 0.662834L10.5324 3.76799C10.6769 4.04013 10.9388 4.23044 11.2423 4.28381L14.7051 4.89275C15.4946 5.03158 15.8081 5.99629 15.251 6.57263L12.8074 9.10066C12.5933 9.32223 12.4932 9.63015 12.5362 9.93528L13.0272 13.4168C13.1391 14.2105 12.3185 14.8067 11.5982 14.455L8.43879 12.9123C8.16189 12.777 7.83811 12.777 7.56122 12.9123L4.40182 14.455C3.68154 14.8067 2.86091 14.2105 2.97283 13.4168L3.46376 9.93528C3.50678 9.63015 3.40673 9.32223 3.19257 9.10066L0.74903 6.57263C0.191951 5.99629 0.505402 5.03158 1.29485 4.89275L4.75766 4.28381C5.06115 4.23044 5.32309 4.04013 5.46763 3.76799L7.11684 0.662837Z"
                    />
                </svg>

            </div>
        </b-form-group>
    </div>
</template>
<script>
import ScheduleListComponent from "@/components/admin/lessons/ScheduleListComponent.vue";
import LessonsListComponent from "@/components/admin/lessons/LessonsListComponent.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
    name: "StudentsProfileForm",
    components: {LessonsListComponent, ScheduleListComponent},
    data () {
        return {
            account: {
                full_name : 'Не установлено',
                birthdate : 'Не установлено',
                age : 'Не установлено',
                age_group : 'Не установлено',
                parent_name : 'Не установлено',
                timezone : 'Не установлено',
                experience_title : 'Не установлено',
                avatar_url: 'https://place-hold.it/200x200',
            },
            lvlTitles: {
                0: 'Новичок',
                1: 'Познаватель',
                2: 'Дебютант',
                3: 'Укротитель одиноких Королей',
                4: 'Проводник',
                5: 'Покоритель Эндшпилей',
                6: 'Дипломат',
                7: 'Тактик',
                8: 'Исследователь доски',
                9: 'Хранитель позиций',
                10: 'Стратег игры',
                11: 'Мастер эндшпилей',
                12: 'Следопыт/Сапер',
                13: 'Мастер Дебютов',
                14: 'Аналитик игры',
                15: 'Мастер Миттельшпиля',
            },
        }
    },
    created() {
        this.account = {...this.account, ...this.getStudentDetail}
        this.getAgeGroupTitle(this.account.age_group)
        this.setUnselectText()
    },
    computed: {
        moment() {
            return moment
        },
        ...mapGetters({
            getStudentDetail: "students/getStudentDetail",
            getCountriesCode: "common/getCountriesCodes",
            getAgeGroups: "common/getAgeGroups",
        }),
        baseUrl() {
            return process.env.VUE_APP_API_BASE_URL;
        },
        studentGender() {
            let studentGender = this.getStudentDetail.gender ? 'Мужской' : 'Женский'
            if (this.getStudentDetail.gender === null) studentGender = 'Не выбран'
            return studentGender
        },
        studentBirthdate() {
            return this.account.birthdate === 'Не установлено' ? this.account.birthdate : moment(this.account.birthdate).format('DD.MM.YYYY')
        },
        studentCountry() {
            let studentCountryCode = this.getStudentDetail.country_code
            let CountriesCode = this.getCountriesCode
            let studentCountryCodeTitle = 'Не установлено'

            if (!(studentCountryCode || CountriesCode)) {
                return studentCountryCodeTitle
            }

            for (const countriesCodeValue of CountriesCode) {
                if ( countriesCodeValue.value === studentCountryCode){
                    studentCountryCodeTitle = countriesCodeValue.text
                    break
                }
            }

            return studentCountryCodeTitle
        },
        getSubLvl() {
            const lvl = this.getStudentDetail?.lvl;
            return (!lvl || lvl === 0) ? 0 : ( lvl % 5 === 0 ? 5 : lvl % 5 );
        },
        getLvlTitle() {
            return this.lvlTitles[this.getStudentDetail.lvl]
        }
    },
    methods: {
        getAgeGroupTitle(age_group) {
            this.getAgeGroups.forEach(item => {
                console.log(item)
                if (item.value === age_group) {
                    this.account.age_group = item.text
                }
            })
        },
        setUnselectText() {
            for (const [key, value] of Object.entries(this.account)) {
                !this.account[key] ? this.account[key] = 'Не установлено': this.account[key] = value;
            }
        },
        async handlePageSelect(bvEvent, page) {
            if (page) {
                const query = Object.assign({}, this.$route.query, { page });
                await this.$router.push({ query: query });
            }

            return false;
        },
    }
}
</script>


<style scoped lang="scss">
.profile-data-lvl-wrap {
    display: flex;
    align-items: center;
    background-color: #EAF0F6;
    padding: 9px 12px;
    border-radius: 0.5rem;

    .lvl-star {
        margin-right: 5px;

        &:last-of-type {
            margin-right: 0;
        }

        &.active {
            path {
                fill: #edb200;
            }
        }

        path {
            fill: #e3e1e1;
        }
    }
}


</style>