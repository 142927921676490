<template>
    <div>
        <Preloader v-if='loader' />
        <b-tabs v-if="!loader && getStudentDetail && Object.keys(getStudentDetail)">
            <b-tab title="Общая информация">
                <StudentProfileShow v-if="getStudentDetail.id"  :student-id="$route.params.id"/>
            </b-tab>

<!--            <b-tab title="Абонементы">-->
<!--                <StudentProducts v-if="getStudentDetail.id" :student-id="$route.params.id" />-->
<!--            </b-tab>-->

<!--            <b-tab title="Платежи">-->
<!--              <StudentProfile v-if="getStudentDetail.id" :student-id="$route.params.id" />-->
<!--            </b-tab>-->
        </b-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Preloader from '@/components/Preloader.vue';
import StudentDuplicate from "@/components/StudentDuplicate.vue";
import StudentProfile from "@/components/admin/students/StudentProfile.vue";
import StudentProducts from "@/components/admin/students/StudentProducts.vue";
import StudentProfileShow from "@/components/admin/students/StudentProfileShow.vue";

export default {
    components: { Preloader, StudentProfileShow, StudentProducts, StudentProfile, StudentDuplicate },
    name: "ShowStudent",
    data() {
        return {
            loader: false,
        }
    },
    async created() {
        this.loader = true;
        await this.loadStudentDetail(this.$route.params.id);
        this.loader = false;
    },
    computed: {
        ...mapGetters({
            getStudentDetail: "students/getStudentDetail",
        }),
    },
    methods: {
        ...mapActions({
            loadStudentDetail: "students/loadStudentDetail",
        }),
    }
};
</script>

<style lang="scss" scoped></style>