<template>
  <div class="padding-around">
    <div class="container">
      <div class="row">
        <template v-if="form.id">
          <h4 class="col-12 mb-3 p-0">Завершить расписание ({{ form.weekdaysFormatted }}) c {{ formatBeginTime }} по
            {{ formatEndTime }}</h4>
          <div class="alert alert-warning text-dark col-12 text-size week-days-changed-alert" role="alert">
            Все занятия этого ученика, созданные позже {{ currentSelectedEndDate }} будут <b><u>удалены</u></b>!
          </div>
          <b-form class="row" @submit.prevent="updateSchedule">
            <b-form-group label="Период действия c:" class="col-6" label-for="selected_end_date">
              <b-form-input readonly :value="formatBeginDate"></b-form-input>
              <b-form-invalid-feedback>Дата занятия не может быть пустой</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="По (включительно):" class="col-6" label-for="selected_end_date">
              <b-form-datepicker
                  id="selected_end_date"
                  :start-weekday="1"
                  v-model="form.selected_end_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  :min="min_date"
                  locale="ru"
                  required
              />
              <b-form-invalid-feedback>Дата занятия не может быть пустой</b-form-invalid-feedback>
            </b-form-group>

            <div class="d-flex justify-content-end col-12">
              <b-form-group class="text-right">
                <b-button type="submit" class="mr-2" variant="success">
                  <b-spinner v-if="isSubmiting" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
                  <span :class="{ captionPadding: isSubmiting }">Сохранить</span>
                </b-button>
                <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
              </b-form-group>
            </div>

          </b-form>
        </template>
        <template v-else>
          <h4 class="col-12 mb-3 p-0">Завершить расписание</h4>
          <b-alert show variant="danger w-100">Произошла ошибка. Не удалось загрузить данные.</b-alert>
          <b-form class="g-1 w-100">
            <div class="w-100 d-flex justify-content-end">
              <b-form-group class="text-right">
                <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
              </b-form-group>
            </div>
          </b-form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment/moment";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CancelScheduleModal",
  props: {
    scheduleId: {
      type: [Number, String],
      required: true,
    },
      actionHandler: {
          type: Function,
          required: true
      },
  },
  data() {
    return {
      form: {
        id: null,
        teacher_id: null,
        selected_end_date: null,
        duration: null
      },
      min_date: moment().utcOffset(3).format('YYYY-MM-DD'),
      isSubmiting: false
    };
  },
  async created() {
    await this.fetchScheduleData()
  },
  computed: {
    currentSelectedEndDate() {
      return moment(`${this.form.selected_end_date} ${moment().endOf("day").format('HH:mm:ss')}`).format('DD.MM.YYYY HH:mm')
    },
    formatBeginDate() {
      return moment(this.form.begin_date).format('DD.MM.YYYY')
    },
    formatBeginTime() {
      return moment(`${this.form.begin_date} ${this.form.begin_time}`).format('HH:mm')
    },
    formatEndTime() {
      return moment(`${this.form.begin_date} ${this.form.end_time}`).format('HH:mm')
    },
  },
  methods: {
    ...mapActions({
      setScheduleRefresh: 'scheduler/scheduleRefresh',
      setLessonsRefresh: 'scheduler/lessonsRefresh',
    }),
    async updateSchedule() {
      try {
        await this.$axios.post(`/crm/schedules/${this.form.id}/finish`, {
          end_date: moment(this.form.selected_end_date).format('YYYY-MM-DD'),
        });
        await this.actionHandler()
        this.isSubmiting = false;
        this.closeModal()
      } catch (e) {
        let errMsg = `Ошибка обновления регулярного занятия.`;
        if (e.response && e.response.data.error) {
          errMsg = e.response.data.error;
        } else if (e.response && e.response.data.message) {
          errMsg = e.response.data.message;
        }
        this.isSubmiting = false;
        this.showErrorNotify(errMsg)
        throw e;
      }
    },
    async fetchScheduleData() {
      try {
        const {data} = await this.$axios.get(`crm/schedules/${this.scheduleId}`);
        this.form = {...this.form, ...data};
        this.form.selected_end_date = moment(this.form.begin_date).isBefore() ?
            moment().utcOffset(3).format('YYYY-MM-DD') :
            moment(this.form.begin_date).format('YYYY-MM-DD')
        this.form.duration = moment(`${this.form.begin_date} ${this.form.end_time}`).diff(moment(`${this.form.begin_date} ${this.form.begin_time}`), 'minutes');
        this.min_date = moment(this.form.begin_date).isBefore() ?
            moment().utcOffset(3).format('YYYY-MM-DD') :
            moment(this.form.begin_date).format('YYYY-MM-DD')
      } catch (ex) {
        this.showErrorNotify(`Ошибка загрузки данных.`)
        console.log("can't fetch student's schedule: " + ex);
      }
    },
    closeModal() {
      this.$emit('close')
    },
    showNotify(text) {
      this.$toasted.success(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    showErrorNotify(text) {
      this.$toasted.error(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
  }
}
</script>


<style scoped lang="scss">
.padding-around {
  padding: 14px 16px 0 14px;
}

.captionPadding {
  margin-left: 10px;
}

.week-days-changed-alert {
  font-size: 14px;
}

.weekdays-checkbox-wrap {
  display: flex;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.weekdays-checkbox-wrap > div {
  margin-right: 1.5rem;
}
</style>