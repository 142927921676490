<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 class="col-12 mt-3 mb-3">Добавить абонемент</h4>
                <b-form
                    v-if="products"
                    class="col-12 form-product"
                    @submit.prevent="handleProductSubmit"
                    @reset="closeModal"
                >
                    <div class="product-wrap">
                        <b-form-group label="Продукт:" class="col-12" label-for="p-input-1">
                            <Multiselect
                                id="p-input-1"
                                class="custom__multiselect"
                                selectLabel="нажмите чтобы выбрать"
                                deselectLabel="нажмите чтобы убрать"
                                tagPosition="bottom"
                                :tagable="true"
                                :custom-label="labelForProduct"
                                track-by="id"
                                placeholder="Выберите Абонемент"
                                :options="products"
                                v-model="product"
                                @input="(value, id) => selectProduct(value, id)"
                            >
                            </Multiselect>
                        </b-form-group>

                        <b-form-group
                            label="Количество:"
                            class="col-12"
                            label-for="p-input-2"
                        >
                            <b-form-input
                                v-if="product"
                                id="p-input-2"
                                type="number"
                                placeholder="Количество"
                                min="0"
                                required
                                v-model.number="lesson_count"
                            />
                            <b-form-input
                                v-else
                                id="p-input-2"
                                type="number"
                                placeholder="Количество"
                                min="0"
                                disabled
                            />
                        </b-form-group>
                    </div>

                    <b-form-group class="col-12 row justify-content-end m-0 mt-5">
                        <b-button type="submit" class="mr-2" variant="success">Добавить</b-button>
                        <b-button type="reset" variant="primary">Закрыть</b-button>
                    </b-form-group>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from "debounce";
    import Multiselect from "vue-multiselect";

    export default {
        name: 'CreateStudentProductModal',
        components: { Multiselect },
        props: {
            actionHandler: {
                type: [Function],
                required: true
            },
            products: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                product: null,
                lesson_count: 0,
            };
        },
        methods: {
            async handleProductSubmit() {
                if (this.product) {
                    await this.actionHandler(this.product.id, this.lesson_count);
                    this.$modal.hide('CreateStudentProductModal');
                }
            },
            closeModal() {
                this.$modal.hide('CreateStudentProductModal');
            },
            labelForProduct(product) {
                return `${product.title} - ${product.language}`;
            },
            selectProduct(product) {
                if (product) {
                    this.lesson_count = product.lesson_count
                }
            },
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">

</style>

