<template>
  <div class="card px-4 py-3" >
    <h5 class="mb-4">Количество свободных абонементов</h5>
    <template v-if="subscriptions && subscriptions.length">
      <ul v-for="(subscription) in subscriptions" :key="subscription.id">
        <li class="list-dot">Абонементов по {{subscription.duration}} минут: {{subscription.lesson_count}} ({{subscription.language}})</li>
      </ul>
    </template>
    <template v-else>
      <p  class="text-center w-100">Нет свободных абонементов</p>
    </template>
  </div>
</template>

<script>

export default {
    name: "StudentPotentialLessons",

    props: {
      studentId: {
        required: true,
        type: String,
      },
      teacherLanguage: {
        required: false,
        type: String,
      }
    },

    data() {
        return {
          subscriptions: null,
        };
    },
    computed: {
    },
    async created() {
      await this.fetchSubscriptions();
    },
    methods: {
      async fetchSubscriptions() {
        const { data } = await this.$axios.get(`/crm/students/${this.studentId}/products-balance`,{});
        this.subscriptions = data.data;
        console.log(this.subscriptions);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .list-dot {
    padding-left: 20px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: var(--sidebar-bg-color);
    }
  }
</style>