<template>
    <div>
        <Preloader v-if='loader' />
        <b-tabs v-if="!loader && getStudentDetail && Object.keys(getStudentDetail)">
            <b-tab title="Общая информация">
                <StudentProfileShow v-if="getStudentDetail.id"  :student-id="$route.params.id"/>
            </b-tab>

            <b-tab title="Учетная запись">
                <StudentAccount v-if="getStudentDetail.id" />
            </b-tab>

            <b-tab title="Аккаунт Lichess">
              <LichessAccount v-if="getStudentDetail.id" />
            </b-tab>

            <b-tab title="Профиль ЛК">
                <StudentProfile v-if="getStudentDetail.id" :student-id="$route.params.id" />
            </b-tab>

            <b-tab title="Абонементы">
                <StudentProducts v-if="getStudentDetail.id" :student-id="$route.params.id" />
            </b-tab>

            <b-tab
                v-if="getStudentDetail.duplicate_id && getStudentDetail.duplicate_id > 0"
                title="Дубликат"
            >
                <StudentDuplicate :duplicate-id="getStudentDetail.duplicate_id" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Preloader from '@/components/Preloader';
import StudentDuplicate from "@/components/StudentDuplicate";
import StudentAccount from "@/components/admin/students/StudentAccount";
import StudentProfile from "@/components/admin/students/StudentProfile";
import StudentProducts from "@/components/admin/students/StudentProducts";
import StudentProfileShow from "@/components/admin/students/StudentProfileShow.vue";
import LichessAccount from "@/components/admin/students/LichessAccount.vue";

export default {
    components: {StudentProfileShow, Preloader, LichessAccount, StudentAccount, StudentProducts, StudentProfile, StudentDuplicate },
    name: "StudentDetail",
    data() {
        return {
            loader: false,
        }
    },
    async created() {
        this.loader = true;
        await this.loadStudentDetail(this.$route.params.id);
        this.loader = false;
    },
    computed: {
        ...mapGetters({
            getStudentDetail: "students/getStudentDetail",
        }),
    },
    methods: {
        ...mapActions({
            loadStudentDetail: "students/loadStudentDetail",
        }),
    }
};
</script>

<style lang="scss" scoped></style>