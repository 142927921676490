<template>
    <div class="student-products">
        <div class='row mb-4 justify-content-between'>
            <h4 class="col-6">Абонементы ученика</h4>
            <div class='col-6 text-right'>
                <button class='button_add' @click="addProduct">Добавить абонемент</button>
            </div>
        </div>

        <div class="widget">
            <table v-if="studentProducts" class='table table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>Название</th>
                    <th>Язык преподования</th>
                    <th>Количество</th>
                    <th class="actions_container">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='sProduct in studentProducts' :key='sProduct.id' class="student-product">
                    <td class="title">{{sProduct.product.title}}</td>
                    <td class="lang">{{sProduct.product.language}}</td>
                    <td class="lesson_count">{{sProduct.lesson_count}}</td>
                    <td class="actions_container">
                        <a class='btn mr-2 btn-primary' @click.prevent='updateAction(sProduct)'>
                            <span class='glyphicon glyphicon-edit' />
                        </a>
                        <a class='btn mr-2 btn-danger' @click.prevent='deleteAction(sProduct.id, sProduct.product.title)'>
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DeleteModal from "@/components/Modal/DeleteModal";
import CreateStudentProductModal from "@/components/admin/students/CreateStudentProductModal";
import UpdateStudentProductModal from "@/components/admin/students/UpdateStudentProductModal";

export default {
    name: "StudentProducts",
    components: { Multiselect, DeleteModal, CreateStudentProductModal, UpdateStudentProductModal },
    props: {
        studentId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            products: [],
            studentProducts: [],
        };
    },
    async created() {
        await this.fetchProducts();
        await this.fetchStudentProducts();
    },
    methods: {
        addProduct() {
            this.$modal.show(
                CreateStudentProductModal,
                {
                    products: this.getProducts(),
                    actionHandler: this.createRecord,
                }, { name: 'CreateStudentProductModal' },
            );
        },
        async createRecord(productId, lessonCount) {
            try {
                await this.$axios.post(`crm/students/${this.studentId}/products/create`, {
                    product_id: productId,
                    lesson_count: lessonCount
                });
                await this.fetchStudentProducts();
            } catch (ex) {
                console.log('cant create student product: ' + ex);
            }
        },
        getProducts() {
            const ids = this.studentProducts.map(sp => sp.product_id);
            return this.products.filter(p => !ids.includes(p.id));
        },
        async updateAction(product) {
            const changeHistory = await this.fetchStudentProductsHistory(product.id);
            this.$modal.show(
                UpdateStudentProductModal,
                {
                    products: this.products,
                    actionHandler: this.updateRecord,
                    selectProduct: product,
                    changeHistory: changeHistory
                }, { name: 'UpdateStudentProductModal', height: "auto" },
            );
        },
        async updateRecord(sProductId, lessonCount) {
            try {
                await this.$axios.post(`crm/students/${this.studentId}/products/${sProductId}/update`, {
                    lesson_count: lessonCount
                });
                await this.fetchStudentProducts();
            } catch (ex) {
                console.log('cant update student product: ' + ex);
            }
        },
        deleteAction(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                }, { name: 'DeleteModal' },
            );
        },
        async deleteRecord(id) {
            try {
                await this.$axios.delete(`crm/students/${this.studentId}/products/${id}/delete`);
                await this.fetchStudentProducts();
            } catch (ex) {
                console.log('cant delete student product: ' + ex);
            }
        },
        async fetchStudentProducts() {
            try {
                const { data } = await this.$axios.get(`crm/students/${this.studentId}/products`);
                this.studentProducts = data.data;
            } catch (ex) {
                console.log('cant fetch student products: ' + ex);
            }
        },
        async fetchProducts() {
            try {
                const { data } = await this.$axios.get("crm/products/list", { params: { full: true } });
                this.products = data.data;
            } catch (ex) {
                console.log("cant fetch products: " + ex);
            }
        },
        async fetchStudentProductsHistory(sProductId) {
            try {
                const { data } = await this.$axios.get(`crm/students/${this.studentId}/products/${sProductId}/change-history`);
                return data.data;
            } catch (ex) {
                console.log('cant fetch student products: ' + ex);
                return [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.product-wrap {
    position: relative;
    display: flex;
    width: 100%;
    flex-basis: 100%;
    padding-right: 20px;
    padding-left: 20px;
}
</style>