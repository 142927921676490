<template >
  <b-card no-body class="schedule-accordion">
    <b-card-header header-tag="header" class="p-0 schedule-accordion-header" role="tab">
      <b-button block v-b-toggle.lessons-accordion variant="info" class="schedule-accordion-button text-left">Список занятий</b-button>
        <b-button variant="info" class="new-lesson-btn" @click="openCreateLessonModal">Создать занятие</b-button>
    </b-card-header>
    <b-collapse id="lessons-accordion" visible accordion="my-accordion-1" role="tabpanel">
      <b-card-body>
        <template v-if="lessons.data && Object.keys(lessons.data).length">
        <div class="mr-5 pr-3 mb-3">
            <lesson-legend-component></lesson-legend-component>
        </div>
          <div class="lessons-list-container mb-2">
            <div class="lessons-nav mb-0 d-flex align-items-center" v-if="lessons.meta.last_page > 1">
              <div class="lessons-nav-item">
                <button @click="lessonDataNextPage" class="lessons-nav-link btn btn-info" :disabled="lessons.meta.last_page === lessons.meta.current_page">
                  <span class='glyphicon glyphicon-chevron-left icon-top-1' />
                </button>
              </div>
            </div>
            <ul class="lessons-list mb-0 mx-2">
              <li class="lessons-list-item"
                  v-for="(item, key) in lessons.data"
                  :key="key"
              >
                <button
                  @click="openLessonDetails(item.id, !isManager)"
                  class="lessons-list-link btn flex-column"
                  :class="{
                    'planned' : item.status === 0,
                    'finished' : item.status === 1,
                    'skipped' : item.status === 2,
                    'canceled' : item.status === 3,
                    'regular' : item.lesson_type === 0,
                    'trial' : item.lesson_type === 1,
                    'tint-lesson': getScheduleHighlight !== item.schedule_id && getScheduleHighlight
                  }"
                  @mouseover="setScheduleHighlight(item.schedule_id)"
                  @mouseleave="setScheduleHighlight(null)"
                  >
                  <span v-if="isManager" class="lesson-teacher-name">{{ item.teacher_name }}</span>
                    {{ dayjs(item.date_from).format('DD.MM.YYYY') }} <br> {{ dayjs(item.date_from).format('HH:mm') }}
                </button>
              </li>
            </ul>
            <div class="lessons-nav mb-0 d-flex align-items-center" v-if="lessons.meta.last_page > 1">
              <div class="lessons-nav-item">
                <button @click="lessonDataPrevPage" class="lessons-nav-link btn btn-info" :disabled="lessons.meta.current_page <= 1">
                  <span class='glyphicon glyphicon-chevron-right icon-top-1' />
                </button>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <b-alert show variant="primary">Результатов не найдено</b-alert>
        </template>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import EditLessonModal from "@/components/Modal/EditLessonModal.vue";
import dayjs from "dayjs";
import LessonLegendComponent from "@/components/LessonLegendComponent.vue";
import {mapActions, mapGetters} from "vuex";
import CreateLessonModal from "@/components/Modal/CreateLessonModal.vue";
import moment from "moment";

export default {
  name: "LessonsListComponent",
  components: {LessonLegendComponent},
  props: {
    studentId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
        isManager: false,
        lessons: {
            data: null,
            meta: null,
            page: 1
        },
    };
  },
  computed: {
    ...mapGetters({
      getScheduleHighlight: "scheduler/getScheduleHighlight",
        getRole: "user/getRole"
    })
  },
  async created() {
      this.isManager = this.getRole === 'admin'
      this.lessons.page = this.$route.query['lessons-page'] ? this.$route.query['lessons-page'] : this.lessons.page
      await this.fetchLessonsData()
  },
  methods: {
    ...mapActions({
      setScheduleHighlight: "scheduler/setScheduleHighlight"
    }),
    dayjs,
    async fetchLessonsData() {
      try {
        const { data } = await this.$axios.get(`crm/students/${this.studentId}/lessons?page=${this.lessons.page}`);
        this.lessons.data = data.data.reverse();
        this.lessons.meta = data.meta;
      } catch (ex) {
        this.showErrorNotify('Не удалось загрузить список уроков')
        console.log("can't fetch student's lessons: " + ex);
      }
    },
    openLessonDetails(teacherId, isTeacher = false, isSchedule = false) {
      this.$modal.show(
          EditLessonModal,
          {
            teacher: isTeacher,
            lessonId: teacherId,
            isSchedule: isSchedule,
            actionHandler: this.updateDataAfterAction,
            removeHandler: this.updateDataAfterAction,
          },
          {
            height: 'auto',
            maxHeight: 900,
            scrollable: true,
          }
      );
    },
    openCreateLessonModal() {
      this.$modal.show(
          CreateLessonModal,
          {
              teacher: !this.isManager,
              isLesson: true,
              lessonDatePrepared: moment().format('DD-MM-YYYY'),
              studentIdPrepared: Number(this.studentId),
              beforeCreatedAction: this.updateDataAfterAction
          },
          {
              height: 'auto',
              maxHeight: 900,
              scrollable: true,
          },
      );
    },
    updateDataAfterAction() {
      this.$emit('update')
    },
    async handlePageSelect(page) {
      if (page) {
        const query = Object.assign({}, this.$route.query, { 'lesson-page': page });
        await this.$router.push({ query: query });
        await this.fetchLessonsData()
      }

      return false;
    },
    lessonDataPrevPage() {
      this.lessons.page--
      this.handlePageSelect(this.lessons.page)
    },
    lessonDataNextPage() {
      this.lessons.page++
      this.handlePageSelect(this.lessons.page)
    },
    showErrorNotify(text) {
      this.$toasted.error(text, {
        position: 'bottom-right',
        action: {
          text: 'Закрыть',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
  }
};
</script>

<style scoped lang="scss">
.schedule-accordion-header {
    position: relative!important;
  border: none!important;
}

.schedule-accordion-button{
    padding: 10px 15px;
  background-color: var(--sidebar-bg-color);
  border-color: var(--sidebar-bg-color);
}

.new-lesson-btn {
    position: absolute;
    zoom: 0.9;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.schedule-accordion-card-header {
  font-weight: 600;
}

.lesson-teacher-name {
    display: block;
    max-width: 74px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    margin-bottom: 3px;
}

.lessons-list-container {
  display: flex;
  justify-content: center;

  .lessons-list {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    grid-gap: 5px;
    width: 100%;

    @media all and (max-width: 1500px) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    @media all and (max-width: 1280px) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @media all and (max-width: 1200px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media all and (max-width: 768px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media all and (max-width: 640px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .lessons-list-item {
      text-align: center;

      .lessons-list-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 10px;
        border-top: 5px solid #fff;
        cursor: pointer;
        text-decoration: none!important;

          &.trial {
              border-top-color: $trial_lesson;
          }

          &.regular {
              border-top-color: $regular_lesson;
          }

          &.planned {
              background-color: $planned_lesson;
          }
          &.finished {
              background-color: $finish_lesson;
          }
          &.skipped {
              background-color: $skipped_lesson;
          }
          &.canceled {
              background-color: $canceled_lesson;
          }

        &.tint-lesson {
          &:after {
            background: rgba(0, 0, 0, 0.6);
          }
        }

        &:after {
          content: '';
          position: absolute;
          min-width: 100%;
          min-height: 100%;
          background: transparent;
          transition: 0.25s;
        }

        &:hover, &:visited {
          text-decoration: none!important;
        }
      }
    }
  }
}

.lessons-nav {
  justify-content: center;
  display: flex;
  gap: 10px;
  .lessons-nav-item {
    .lessons-nav-link {
      border: 1px solid #EAF0F6;
      padding: 5px;
      text-decoration: none!important;
      cursor: pointer;
        height: 70px;
        width: 40px;
      &:hover, &:visited {
        text-decoration: none!important;
      }
      &.disabled {
        opacity: 0.8;
        pointer-events: none;
      }
    }
  }
}

.icon-top-1 {
    top: -1px;
}
</style>