<template>
    <div class="student-account">
        <h4 class="mb-4">Аккаунт {{ form.full_name }}
          <span v-if="this.getStudentDetail.status === 0" class="ml-2 badge bg-warning text-info-light">Саморегестрация</span>
          <span v-if="this.getStudentDetail.status === 1" class="ml-2 badge bg-success text-info-light">Занимается</span>
          <span v-if="this.getStudentDetail.status === 2" class="ml-2 badge bg-danger text-info-light">Закончил обучение</span>
          <span v-if="this.getStudentDetail.status === 3" class="ml-2 badge bg-warning text-info-light">Промо-урок</span>
        </h4>
        <div class="widget">
            <b-form class="row g-1" @submit="submitForm" @reset="back">
                <b-form-group
                    label="ФИО ученика:"
                    class="col-6"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-1"
                        v-model="form.full_name"
                        type="text"
                        placeholder="Имя ученика"
                        required
                        :state="nameValidation"
                    />
                    <b-form-invalid-feedback>
                        Имя не может состоять из 2-х букв
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Возраст:"
                    class="col-3"
                    label-for="input-2"
                >
                    <b-form-input
                        id="input-2"
                        v-model="form.age"
                        type="number"
                        placeholder="Возраст"
                        required
                        :state="ageValidation"
                    />
                    <b-form-invalid-feedback>
                        Укажите возраст
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Возрастная группа ученика: *"
                    min="2"
                    class="col-3"
                    label-for="age-group"
                >
                    <b-form-select
                        id="age-group"
                        required
                        :state="ageGroupsValidation"
                        v-model="form.age_group"
                    >
                        <b-form-select-option v-for='(value) in getAgeGroups' :key='value.value' :value='value.value'>{{value.text}}</b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback>
                        Выберете возрастную группу
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Опыт:"
                    class="col-6"
                    label-for="input-3"
                >
                    <b-form-select
                        id="input-3"
                        :options="getExperienceList"
                        :value="form.experience"
                        v-model="form.experience"
                        :state="expierenceValidation"
                    />
                    <b-form-invalid-feedback>
                        Выберите опыт
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Город"
                    class="col-3"
                    label-for="input-4"
                >
                    <b-form-input
                        id="input-4"
                        v-model="form.city"
                        type="text"
                        placeholder="город"
                    />
                </b-form-group>

                <b-form-group
                    label="Страна"
                    class="col-3"
                    label-for="input-5"
                >
                    <b-form-select
                        id="input-5"
                        :options="getCountriesCode"
                        :value="form.country_code"
                        v-model="form.country_code"
                    />
                </b-form-group>

                <b-form-group
                    label="Статус студента:"
                    class="col-6"
                    label-for="input-6"
                >
                    <b-form-select
                        id="input-6"
                        :options="getStudentStatusList"
                        :value="form.status"
                        v-model="form.status"
                        required
                    />
                </b-form-group>

                <b-form-group
                    label="Бонусы:"
                    class="col-6"
                    label-for="input-7"
                >
                    <b-form-input
                        id="input-7"
                        v-model="form.balance"
                        type="number"
                        placeholder="Бонусы"
                        required
                        min="0"
                        :state="balanceValidation"
                    />
                    <b-form-invalid-feedback>
                        Обязателен для заполнения
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Логин от аккааунта:"
                    class="col-6"
                    label-for="input-lgn"
                >
                    <b-form-input
                        id="input-lgn"
                        v-model="form.login"
                        type="text"
                        placeholder="Логин"
                    />
                </b-form-group>

                <b-form-group
                    label="Изменить пароль от аккаунта:"
                    class="col-6"
                    label-for="input-pwd"
                >
                    <b-form-input
                        id="input-pwd"
                        v-model="form.password"
                        type="text"
                        placeholder="Оставьте поле пустым, если не требуется смена пароля"
                        minlength="6"
                    />
                </b-form-group>

                <hr style="width: 100%;" />

                <b-form-group
                    label="ФИО родителя:"
                    class="col-6"
                    label-for="input-8"
                >
                    <b-form-input
                        id="input-8"
                        v-model="form.parent_name"
                        type="text"
                        placeholder="ФИО родителя"
                        required
                        :state="parentNameValidation"
                    />
                    <b-form-invalid-feedback>
                        Имя не может состоять из 2-х букв
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Телефон родителя:"
                    class="col-6"
                    label-for="input-9"
                >
                    <b-form-input
                        id="input-9"
                        v-model="form.parent_phone"
                        type="tel"
                        placeholder="телефон родителя"
                        required
                        v-mask="'####################'"
                        :state="phoneNumberValidator"
                    />
                    <b-form-invalid-feedback>
                        Номер не может состоять из букв или быть меньше 11 цифр
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Email родителя:"
                    class="col-6"
                    label-for="input-122"
                >
                    <b-form-input
                        id="input-122"
                        v-model="form.parent_email"
                        type="email"
                        placeholder="Email родителя"
                    />
                </b-form-group>

                <b-form-group
                    label="Телефон родителя 2:"
                    class="col-6"
                    label-for="input-10"
                >
                    <b-form-input
                        id="input-10"
                        v-model="form.parent_phone2"
                        type="tel"
                        placeholder="телефон родителя 2"
                        v-mask="'####################'"
                    />
                    <b-form-invalid-feedback>
                        Номер не может состоять из букв или быть меньше 11 цифр
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Часовой пояс:"
                    class="col-6"
                    description="Часовые пояса."
                >
                    <b-form-select
                        :options="getAllTimezones"
                        :value="form.timezone"
                        v-model="form.timezone"
                        :state="timezoneValidation"
                    />
                    <b-form-invalid-feedback>
                        Выберите часовой пояс
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    label="Способ уведомлений:"
                    class="col-6"
                    label-for="notification"
                >
                    <Multiselect
                        id="notification"
                        v-model="form.notifications_way"
                        :taggable="true"
                        :close-on-select="false"
                        :multiple="true"
                        :options="getNotificationWay"
                        :value="form.notifications_way"
                        required
                    >
                    </Multiselect>

                    <b-form-invalid-feedback v-if="!notificationValidatation" style="display: block;">
                        Поле обязательно для заполнения
                    </b-form-invalid-feedback>
                </b-form-group>

              <b-form-group
                  label="Комментарий:"
                  class="col-6"
                  label-for="input-comment"
              >
                <b-form-textarea
                    id="input-comment"
                    v-model="form.comment"
                    type="text"
                    placeholder="Комментарий"
                />
              </b-form-group>


                <hr style="width: 100%;" />

                <div class="col-12">
                    <h5>Контроль</h5>
                </div>

                <b-form-group
                    label="Контроль по задолженности"
                    class="col-6"
                >
                    <b-form-select
                        :options="[
                        { value: 0, text: 'Нет' },
                        { value: 1, text: 'На контроле' },
                      ]"
                        v-model="form.debtor"
                    />
                </b-form-group>

                <b-form-group
                    label="Преподаватель"
                    class="col-3"
                >
                    <b-form-select
                        :options="teacherList"
                        v-model="form.teacher_id"
                        value-field="id"
                        text-field="full_name"
                    />
                </b-form-group>

                <b-form-group
                    v-if="!form.teacher_id && suggestedTeacher"
                    class="col-3 chc"
                    label="Возможный преподаватель"
                >
                    <b-button variant="default" @click.prevent="setSuggestedTeacher()">
                        {{ this.suggestedTeacher.full_name }}
                    </b-button>
                </b-form-group>

                <b-form-group class="col-12 text-right">
                    <b-button
                        type="submit"
                        class="mr-2"
                        variant="success"
                        :disabled="!canSend"
                    >
                        Сохранить
                    </b-button>
                    <b-button type="reset" variant="primary">Назад</b-button>
                </b-form-group>
            </b-form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Multiselect from "vue-multiselect";


export default {
    name: "StudentAccount",
    components: { Multiselect },
    data() {
        return {
            form: {
                id: null,
                full_name: null,
                age: null,
                age_group: null,
                balance: 0,
                parent_name: null,
                parent_email: null,
                experience: null,
                experience_title: null,
                parent_phone: null,
                parent_phone2: null,
                notifications_way: [],
                timezone: null,
                created_at: null,
                updated_at: null,
                city: null,
                comment: null,
                debtor: 0,
                teacher_id: null,
                status: null,
                lichess_username: null,
                duplicate_id: null,
                country_code: null,
                lvl: null,
                sub_lvl: null,
                login: null,
            },
            utc: [],
            date: "",
            time: "",

            teacherList: [],
            suggestedTeacher: null,
        };
    },
    async created() {
        dayjs.locale("ru");
        dayjs.extend(duration);

        await this.fetchData()
    },
    computed: {
        ...mapGetters({
            getAllTimezones: "common/getAllTimezones",
            getStudentDetail: "students/getStudentDetail",
            getExperienceList: "common/getExperienceList",
            getNotificationWay: "common/getNotificationWay",
            getStudentStatusList: "common/getStudentStatusList",
            getCountriesCode: "common/getCountriesCodes",
            getAgeGroups: "common/getAgeGroups",
        }),
        timezoneValidation() {
            return !!this.form.timezone;
        },
        nameValidation() {
            return this.form.full_name ? this.form.full_name.length >= 2 : false;
        },
        ageValidation() {
            return this.form.age !== null;
        },
        balanceValidation() {
            return this.form.balance !== null;
        },
        parentNameValidation() {
            return this.form.parent_name ? this.form.parent_name.length > 2 : false;
        },
        expierenceValidation() {
            return this.form.experience >= 0 && this.form.experience != null;
        },
        ageGroupsValidation() {
            return (this.form.age_group !== null);
        },
        phoneNumberValidator() {
            return this.form.parent_phone ? this.form.parent_phone.length >= 11 : false;
        },
        notificationValidatation() {
            return this.form.notifications_way.length !== 0;
        },
        canSend() {
            return this.nameValidation
                & this.ageValidation
                & this.parentNameValidation
                & this.expierenceValidation
                & this.phoneNumberValidator
                & this.timezoneValidation
                & this.notificationValidatation;
        },
    },
    methods: {
        ...mapActions({
            loadStudentDetail: "students/loadStudentDetail",
            updateStudent: "students/updateStudent",
            createStudent: "students/createStudent",
            notifyUser: "common/notifyUser",
        }),
        async setSuggestedTeacher() {
            await this.$axios.post(`/api/set-suggested-teacher/${this.$route.params.id}/${this.suggestedTeacher.id}`);
            await this.loadStudentDetail(this.$route.params.id);
            this.fillStudentData();
        },

        async submitForm(e) {
            e.preventDefault();

            try {
                let data = {
                    ...this.form,
                    notifications_way: this.form.notifications_way.join(","),
                };
                let result = await this.updateStudent(data);

                if (result.status === "success") {
                    await this.loadStudentDetail(this.$route.params.id);
                    this.$toasted.success("Данные обновлены!", {
                        position: "bottom-right",
                        action: {
                            text: "Закрыть",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                } else {
                    const error = new Error();
                    error.status = result.status;
                    error.errors = result.errors;
                    throw error;
                }
            } catch (ex) {
                const errors = ex?.errors ? Object.values(ex.errors) : '';
                console.log(ex);

                this.$toasted.error('Не удалось сохранить!\n' + errors, {
                    position: "bottom-right",
                    action: {
                        text: "Закрыть",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
        },
        async fetchData() {
          try {
            this.teacherList = (await this.$axios.get("/api/teachers")).data.items;
            this.suggestedTeacher = (await this.$axios.get(`/api/get-suggested-teacher/${this.$route.params.id}`)).data;
            this.fillStudentData();
          } catch (e) {
            alert(e);
          }
        },
        back() {
            this.$router.back();
        },
        fillStudentData() {
            for (const key in this.getStudentDetail) {
                const formKeys = Object.keys(this.form);
                if (formKeys.includes(key)) {
                    if (key === "notifications_way") {
                        this.form[key] = this.getStudentDetail[key].split(",");
                    } else {
                        this.form[key] = this.getStudentDetail[key];
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.available {
    background: #AAAAAA !important;
    border: #AAAAAA;
}
</style>