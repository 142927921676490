<template>
    <div>
        <div class='row'>
            <div class='col-6'>
                <h4 class='text-center font-weight-bold'>Сохраняемая запись</h4>
                <div>
                    <div class='form-group'>
                        <label>фио</label>
                        <div>{{ getStudentDetail.full_name }}</div>
                    </div>
                    <div class='form-group'>
                        <label>родитель</label>
                        <div>{{ getStudentDetail.parent_name }}</div>
                    </div>
                    <div class='form-group'>
                        <label>телефон родителя</label>
                        <div>{{ getStudentDetail.parent_phone }}</div>
                    </div>
                    <div class='form-group'>
                        <label>второй номер</label>
                        <div>{{ getStudentDetail.parent_phone2 }}</div>
                    </div>
                    <div class='form-group'>
                        <label>email родителя</label>
                        <div>{{ getStudentDetail.parent_email }}</div>
                    </div>
                    <div class='form-group'>
                        <label>имя пользователя на игровой платформе:</label>
                        <div>{{ getStudentDetail.lichess_username }}</div>
                    </div>
<!--                    <p v-for='item of getStudentDetail' :key='item.id'>-->
<!--                        {{ item }}-->
<!--                    </p>-->
                </div>
            </div>
            <div class='col-6'>
                <h4 class='text-center font-weight-bold'>Дубликат</h4>

                <div>
                    <div class='form-group'>
                        <label>фио</label>
                        <div>{{ duplicate.full_name }}</div>
                    </div>
                    <div class='form-group'>
                        <label>родитель</label>
                        <div>{{ duplicate.parent_name }}</div>
                    </div>
                    <div class='form-group'>
                        <label>телефон родителя</label>
                        <div>{{ duplicate.parent_phone }}</div>
                    </div>
                    <div class='form-group'>
                        <label>второй номер</label>
                        <div>{{ duplicate.parent_phone2 }}</div>
                    </div>
                    <div class='form-group'>
                        <label>email родителя</label>
                        <div>{{ duplicate.parent_email }}</div>
                    </div>
                    <div class='form-group'>
                        <label>имя пользователя на игровой платформе:</label>
                        <div>{{ duplicate.lichess_username }}</div>
                    </div>
                    <!--                    <p v-for='item of getStudentDetail' :key='item.id'>-->
                    <!--                        {{ item }}-->
                    <!--                    </p>-->
                </div>
            </div>
            <div class='col-12 mt-5'><span class='warning'>ВНИМАНИЕ! Обязательно сохраните данные дубликата вручную, если есть необходимость, после слияния, дубликат исчезнет</span></div>
            <div class='col-12 mt-5'>
                <button class='btn btn-primary' @click='mergeStudent'>объединить</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'StudentDuplicate',
    props: {
        duplicateId: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            duplicate: null,
        };
    },
    computed: {
        ...mapGetters({
            getStudentDetail: 'students/getStudentDetail',
        })
    },
    methods: {
        async mergeStudent() {
            try {
                const merge = await this.$axios.get(`api/students/merge/${this.$route.params.id}`);
                if (merge.data && merge.data.success) {
                    this.$router.go(this.$route.path)
                    this.$toasted.success("Успешно", {position: "bottom-right"})
                }
            } catch (e) {
                this.$toasted.error(e.message, {position: "bottom-right"})
            }
        }
    },
    async created() {
        try {
            const response = await this.$axios.get(`api/students/${this.duplicateId}`);
            if (response.data) {
                this.duplicate = response.data;
            }
        } catch (e) {
            this.$toasted.error('Что-то пошло не так ' + e.message, { position: 'bottom-right' });
        }
    },
};
</script>

<style scoped lang='scss'>
.form-group {
    label {
        color: #9d9d9d;
        margin-bottom: 0px;
    }
    div {
        color: #000;
        font-weight: 500;
    }
}
.warning {
    //padding: 5px;
    background: rgba(orange, .2);
    padding: 5px 15px;
    font-weight: 300;
    border-radius: 5px;
}
</style>