<template>
    <div>
        <b-tabs>
            <b-tab title="Аккаунт">
                <StudentAccount />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import StudentAccount from "@/components/admin/students/create/StudentAccount";

export default {
    components: { StudentAccount },
    name: "StudentDetail",
};
</script>

<style lang="scss" scoped></style>