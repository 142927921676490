<template>
  <div class="card px-4 py-3">
    <h5 class="mb-4">Повышенная ставка</h5>
    <template v-if="increasedSalaryLeft && increasedSalaryLeft !== 0">
      <p class="text-center w-100 text-primary">Осталось {{ increasedSalaryLeft }} урока(-ов) до повышенной ставки</p>
    </template>
    <template v-else-if="increasedSalaryLeft === 0">
      <p class="text-center w-100 text-success">Ура! Вы работаете по повышенной ставке!</p>
    </template>
    <template v-else-if="increasedSalaryCounter.is_loaded">
      <div class="d-flex justify-content-center align-items-center">
        <b-spinner
          style='width: 1.4rem;
        height: 1.4rem;'
          label='Sending'
        ></b-spinner>
      </div>
    </template>
    <template v-else>
      <p class="text-center w-100 text-danger">{{ increasedSalaryCounter.error_fetch_message }}</p>
    </template>
  </div>
</template>

<script>

export default {
  name: "TeacherIncreasedSalaryCounter",

  props: {
    studentId: {
      required: true,
      type: String,
    },
    teacherId: {
      required: true,
      type: Number,
    },
    teacherLanguage: {
      required: false,
      type: String,
    }
  },

  data() {
    return {
      increasedSalaryCounter: {
        count: null,
        target_count: null,
        error_fetch_message: 'Не удалось загрузить данные',
        is_loaded: false
      },
    };
  },
  computed: {
    increasedSalaryLeft() {
      if (
        (this.increasedSalaryCounter.count === undefined || this.increasedSalaryCounter.count === null) ||
        (this.increasedSalaryCounter.target_count === undefined || this.increasedSalaryCounter.target_count === null)
      ) {
        return null
      }

      let result = this.increasedSalaryCounter.target_count - this.increasedSalaryCounter.count

      if (result < 0) {
        return 0;
      }

      return result
    }
  },
  async created() {
    await this.fetchSubscriptions();
  },
  methods: {
    async fetchSubscriptions() {
      this.increasedSalaryCounter.is_loaded = true
      try {
        const {data} = await this.$axios.get(`/crm/teachers/${this.teacherId}/students/${this.studentId}/increased-salary-progress`, {});

        if (data && data.error) {
          console.log(data.error);
          return;
        }

        this.increasedSalaryCounter.count = data.data.count;
        this.increasedSalaryCounter.target_count = data.data.target_count;
        this.increasedSalaryCounter.is_loaded = false
      } catch (e) {
        this.increasedSalaryCounter.is_loaded = false
        console.log('произошла ошибка запроса', e);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.list-dot {
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: var(--sidebar-bg-color);
  }
}
</style>