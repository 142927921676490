<template>
    <div class="student-account">
        <div class="widget">
            <b-form class="row g-1" @reset="back">
                <b-form-group
                    label="Логин:"
                    class="col-6"
                    label-for="input-lichess-username"
                >
                    <b-form-input
                        id="input-lichess-username"
                        v-model="form.login"
                        type="text"
                        placeholder=""
                    />
                </b-form-group>

                <b-form-group
                  label="Пароль:"
                  class="col-6"
                  label-for="input-lichess-username"
                >
                  <b-form-input
                    id="input-lichess-username"
                    v-model="form.password"
                    type="text"
                    placeholder=""
                  />
                </b-form-group>

                <div class="col-12 mt-2">
                  <span class="pt-2 pb-2 badge bg-warning text-info-light w-100"><b>Важно!</b> При нажатии кнопки "Сохранить", изменения в полях "Логин" или "Пароль" будут внесены только для CRM. Это действие <b>НЕ ОБНОВИТ их на платформе Lichess!</b></span>
                </div>

                <div class="col-12 mt-4">
                  <div class="row">
                    <b-form-group class="col-6 text-left">
                      <b-button
                        type="button"
                        class="mr-2"
                        variant="success"
                        :disabled="!canRegister"
                        @click="register"
                      >
                        Зарегистрировать пользователя
                      </b-button>
                    </b-form-group>
                    <b-form-group class="col-6 text-right">
                      <b-button
                        type="button"
                        class="mr-2"
                        variant="success"
                        :disabled="!canSave"
                        @click="updateDetail"
                      >
                        Сохранить
                      </b-button>
                      <b-button type="reset" variant="primary">Назад</b-button>
                    </b-form-group>
                  </div>
                </div>

            </b-form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Nprogress from "nprogress";


export default {
    name: "LichessAccount",
    components: { Multiselect },
    data() {
        return {
            form: {
                login: null,
                password: null,
            },
            canRegister: true
        };
    },
    async created() {
        await this.fetchData()
    },
    computed: {
        ...mapGetters({
            getStudentLichessDetail: "students/getStudentLichessDetail",
        }),

        loginValidator() {
          return this.form.login ? this.form.login.length > 6 : false;
        },
        passwordValidator() {
          return this.form.password ? this.form.password.length > 6 : false;
        },
        canSave() {
          return this.loginValidator & this.passwordValidator
        },
    },
    methods: {
        ...mapActions({
            loadStudentLichessDetail: "students/loadStudentLichessDetail",
            updateStudentLichess: "students/updateStudentLichess",
            registerStudentLichess: "students/registerStudentLichess",
        }),
        async fetchData() {
          try {
            await this.loadStudentLichessDetail(this.$route.params.id)
            this.form.login = this.getStudentLichessDetail.data.login
            this.form.password = this.getStudentLichessDetail.data.password
          } catch (e) {
            let errMsg = e.response && e.response.data.message ? e.response.data.message : `Ошибка загрузки данных, обратитесь в поддержку!`;
            this.showErrorNotify(errMsg)
          }
        },
        async updateDetail() {
          try {
            await this.updateStudentLichess({
              id: this.$route.params.id,
              params: {...this.form}
            })
            this.showNotify("Данные успешно обновлены!")
            await this.fetchData()
          } catch (e) {
            let errMsg = e.response && e.response.data.message ? e.response.data.message : `Ошибка во время обновления данных, обратитесь в поддержку!`;
            this.showErrorNotify(errMsg)
            Nprogress.done()
          }
        },
        async register() {
          try {
            await this.registerStudentLichess({
              id: this.$route.params.id,
              params: {...this.form}
            })
            this.showNotify("Пользователь успешно зарегистрирован!")
            await this.fetchData()
          } catch (e) {
            let errMsg = e.response && e.response.data.message ? e.response.data.message : `Ошибка во время регистрации, обратитесь в поддержку!`;
            this.showErrorNotify(errMsg)
            Nprogress.done()
          }
        },
        showErrorNotify(text) {
          this.$toasted.error(text, {
            position: 'bottom-right',
            action: {
              text: 'Закрыть',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        },
        showNotify(text) {
          this.$toasted.success(text, {
            position: 'bottom-right',
            action: {
              text: 'Закрыть',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              },
            },
          });
        },
        back() {
            this.$router.back();
        }
    },
};
</script>

<style lang="scss" scoped>
.available {
    background: #AAAAAA !important;
    border: #AAAAAA;
}
</style>